import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons"

const PreviousLink = styled(Link)`
  margin-right: auto;
  order: 1;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600
`

const NextLink = styled(Link)`
  margin-left: auto;
  order: 2;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600
`
const FlexBox = styled.div`
    display: flex; 
    flex-flow: row wrap;
    justify-content: space-around;
`

const PostLinks = props => {
    return (
        <>
            <FlexBox>
                {props.previous && (
                    <PreviousLink to={`/blog/${props.previous.slug}/`}>
                    <FontAwesomeIcon icon={faAngleLeft} aria-hidden="true" /> Previous
                    </PreviousLink>
                )}
                {props.next && (
                    <NextLink to={`/blog/${props.next.slug}/`}>
                        Next <FontAwesomeIcon icon={faAngleRight} aria-hidden="true" />
                    </NextLink>
                )}
            </FlexBox>
        </>
    )
}

export default PostLinks